/** @jsxImportSource @emotion/react */
import React from 'react'
import useIsLandscape from '../../hooks/useScreenOrientation'
import { css } from '@emotion/react'
import orientation from '../../images/orientation.gif'
import BrowserThemeColor from '../ThemeColor/BrowserThemeColor'
import { photosTheme as theme } from 'mixtiles-web-common'
import { translateManager as t } from '../../services/TranslateManager'
import useIsMediumScreen from '../../hooks/useIsMediumScreen'

const overlayStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.white};
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
`

const iconStyles = css`
  margin-bottom: 16px;
  width: 101px;
  height: 101px;
`

const textStyles = css`
  color: #eb2371;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
`

interface LandscapeWarningProps {
  children: React.ReactNode
}

function LandscapeWarning({ children }: LandscapeWarningProps): JSX.Element {
  const isLandscape = useIsLandscape()
  const isMediumScreen = useIsMediumScreen()

  // Only show warning on small devices in landscape mode
  if (!isLandscape || isMediumScreen) {
    return <>{children}</>
  }

  return (
    <>
      <BrowserThemeColor color={theme.colors.white} />
      <div css={overlayStyles}>
        <div css={iconStyles}>
          <img
            src={orientation}
            alt="Rotate phone icon"
            width="101"
            height="101"
          />
        </div>
        <div css={textStyles}>{t.get('general.rotate_phone')}</div>
      </div>
      {children}
    </>
  )
}

export default LandscapeWarning
