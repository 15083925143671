import { useState } from 'react'
import useOnMount from './useOnMount'

const useIsLandscape = () => {
  const [isLandscape, setIsLandscape] = useState(false)

  useOnMount(() => {
    const mediaQuery = window.matchMedia('(orientation: landscape)')
    setIsLandscape(mediaQuery.matches)

    const handleOrientationChange = (e: MediaQueryListEvent) => {
      setIsLandscape(e.matches)
    }

    mediaQuery.addEventListener('change', handleOrientationChange)

    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange)
    }
  })

  return isLandscape
}

export default useIsLandscape
