import { promoCodeManager } from '../../services/PromoCodeManager'
import {
  DISCOUNT_TYPES,
  PROMO_CODE_SOURCE,
} from '../../stores/promoCodeSlice/promoCodeConsts'
import React, { useEffect, useRef, useState } from 'react'
import { ONBOARDING_URL } from '../../config/config'
import { genericBannerManager } from '../../services/GenericBannerManager'
import { useLocation } from '@remix-run/react'
import {
  hasStoredPromoCode,
  removeStoredPromoCode,
} from '../../services/promoCodeStore'
import {
  getPendingCouponDialog,
  removePendingCouponDialog,
  setPendingCouponDialog,
} from './pendingCouponDialog'
import useBundleDiscount from '../../hooks/useBundleDiscount'
import {
  MATERIAL_TYPES,
  TILE_SIZES,
  getSizesData,
} from '@mixtiles/web-backend-shared'
import {
  calculateDiscountPercent,
  formatPrice,
  formatPriceWithPrecision,
} from '../../utils/utils'
import { translateManager as t } from '../../services/TranslateManager'
import { getSizeName, isSizes } from '../../services/SizesManager'
import {
  ICON_SIZES,
  SNACKBAR_VARIANTS,
} from '../../elements/Snackbar/Snackbar.consts'
import { useSnackbar } from 'notistack'
import useDialog from '../../elements/Dialog/DialogProvider'
import GiftCardSuccessDialog from '../PromoCodeDialog/GiftCardSuccessDialog/GiftCardSuccessDialog'
import { ReactComponent as TagPinkSVG } from '../../icons/assets/TagPink.svg'
import { useEmbeddedLogin } from '../EmbeddedLogin/EmbeddedLoginProvider'
import { useStore } from '../../stores/store'
import { experimentManager } from '../../services/ExperimentManager/ExperimentManager'
import { getBestDiscountFromMixType } from '../../services/promoCodeTextGenerator'

function PromoCodeDialogContainer() {
  // This handles cases when a promo code is loaded and updated in the promoCodeManager prior to mounting this component.
  // From that point onwards the subscription in useEffect covers us.
  const snackbarShownOnce = useRef(false)
  const location = useLocation()
  const [{ coupon, hideCouponPopup, source }, setCouponConfig] = useState({})
  const dialog = useDialog()
  const subscriptionRef = useRef()
  const {
    isLoading: isLoadingDiscount,
    discount,
    originalBundlePrice,
    pricingData,
  } = useBundleDiscount({
    tileSize: TILE_SIZES.SQUARE_8X8,
    materialType: MATERIAL_TYPES.CLASSIC,
  })
  const { enqueueSnackbar } = useSnackbar()
  const { isOpen: isEmbeddedLoginOpen } = useEmbeddedLogin()
  const storedCouponData = useStore((state) => state.couponData)
  const autoLoadOfGivenCoupon = useStore((state) => state.autoLoadOfGivenCoupon)
  const getDiscountCouponCode = useStore((state) => state.getDiscountCouponCode)

  const loadCoupon = (couponData) => {
    let autoCouponLoad
    let hideCouponPopup = false
    if (couponData) {
      const { coupon, couponTitle, source } = couponData
      hideCouponPopup = !!couponData.hideCouponPopup
      // Prevent coupon popup if the user is on the homepage and the coupon was loaded from the banner
      if (source === PROMO_CODE_SOURCE.BANNER && location.pathname === '/') {
        return
      }
      if (
        coupon !== promoCodeManager.getDiscountCouponCode() &&
        !experimentManager.isEnabled('checkout-v2')
      ) {
        autoCouponLoad = promoCodeManager.autoLoadOfGivenCoupon(
          coupon,
          couponTitle,
          source
        )
      } else if (
        experimentManager.isEnabled('checkout-v2') &&
        coupon !== getDiscountCouponCode()
      ) {
        autoCouponLoad = autoLoadOfGivenCoupon(coupon, couponTitle, source)
      }
    } else if (
      (!promoCodeManager.getDiscountCoupon() || hasStoredPromoCode()) &&
      !experimentManager.isEnabled('checkout-v2')
    ) {
      autoCouponLoad = promoCodeManager.autoLoadOfCoupon()
      removeStoredPromoCode()
    }

    if (autoCouponLoad) {
      autoCouponLoad
        .then(({ coupon, couponTitle, source }) => {
          setPendingCouponDialog({ coupon, couponTitle, hideCouponPopup })
          setCouponConfig({ coupon, couponTitle, hideCouponPopup, source })
        })
        .catch(() => {
          // Failed loading coupon
        })
    }
  }

  const isHomepage = location.pathname === '/'
  useEffect(() => {
    promoCodeManager.removeAllExpiredDiscountCoupons()
    if (getPendingCouponDialog()) {
      const { coupon, couponTitle, hideCouponPopup } = getPendingCouponDialog()
      setCouponConfig({ coupon, couponTitle, hideCouponPopup, source })
    }

    subscriptionRef.current = promoCodeManager.couponAutoLoadSubject.subscribe(
      (couponData) => {
        loadCoupon(couponData)
      }
    )

    return () => {
      subscriptionRef.current?.unsubscribe()
      subscriptionRef.current = null
    }
  }, [isHomepage])

  useEffect(() => {
    loadCoupon(storedCouponData)
  }, [storedCouponData])

  const displaySnackBar = () => {
    const price = (value) =>
      formatPriceWithPrecision(value, pricingData.currency)
    const discountValue = discount.value
    const title = t.get('general.menu.add_promo_code.success.code_applied', {
      v1: coupon.code,
    })
    let subtitle
    if (coupon.discountType === DISCOUNT_TYPES.UNIT) {
      const sizesData = getSizesData()

      const defaultSizeConstraintText = getSizeName(TILE_SIZES.SQUARE_8X8)
      const sizeConstraintText = isSizes()
        ? ` (${
            (discount?.metadata?.tileSizesList?.[0] &&
              sizesData[discount?.metadata.tileSizesList[0]] &&
              getSizeName(discount?.metadata.tileSizesList[0])) ||
            defaultSizeConstraintText
          })`
        : ''

      subtitle = t.get(
        'general.promo_code_success_dialog.title.free_tiles_many',
        {
          v1: coupon.amount,
          size_constraint: sizeConstraintText,
        }
      )
    } else if (coupon.discountType === DISCOUNT_TYPES.GIFT_CARD) {
      subtitle = t.get('general.promo_code_success_dialog.subtitle.gift_card', {
        v1: formatPriceWithPrecision(
          coupon.metadata.remainingValue,
          coupon.metadata.currency
        ),
      })
    } else if (coupon.discountType === DISCOUNT_TYPES.BUYOVER) {
      subtitle = t.get(
        'order.checkout.payment_summary_rows.buy_over_percent_off_orders_over_price',
        {
          v1: discountValue.metadata.buyOverDiscount,
          v2: `${price(discountValue.metadata.buyOverPrice)}`,
        }
      )
    } else if (coupon.discountType === DISCOUNT_TYPES.MIX) {
      const bestDiscount = getBestDiscountFromMixType(
        coupon,
        'product_selection.tiles_name',
        pricingData.value,
        price
      )
      subtitle = t.get(
        'general.promo_code_success_dialog.save_up_to_message_shortened',
        {
          v1: bestDiscount.value,
        }
      )
    } else {
      let percentOff
      const couponData = promoCodeManager.getDiscountCoupon()
      switch (coupon.discountType) {
        case DISCOUNT_TYPES.BUYXGETY:
          percentOff = Math.round(
            (discountValue.metadata.get * 100) /
              (discountValue.metadata.buy + discountValue.metadata.get)
          )
          couponData.metadata.percentOff = percentOff
          promoCodeManager.editDiscountCoupon(couponData)
          break
        case DISCOUNT_TYPES.PERCENT:
          percentOff = discountValue.discount.percent_off
          break
        default:
          percentOff = calculateDiscountPercent(
            discountValue?.metadata?.bundlePrice,
            originalBundlePrice
          )
      }

      subtitle = t.get(
        'general.promo_code_success_dialog.size_and_material_disclaimer',
        { v1: `${percentOff}` }
      )
    }
    enqueueSnackbar('', {
      variant: SNACKBAR_VARIANTS.COMMON,
      title,
      subtitle,
      icon: <TagPinkSVG />,
      iconSize: ICON_SIZES.BIG,
      name: 'Promo Code',
    })
  }

  useEffect(() => {
    // Prevent promo code popup during onboarding. Instead, postpone and show it once
    // the onboarding is finished. It is possible due to the fact that the current
    // effect runs on every path change
    if (location.pathname?.startsWith(ONBOARDING_URL)) {
      return
    }
    if (isEmbeddedLoginOpen) {
      return
    }

    promoCodeManager.startMonitoringTime()
    genericBannerManager.ensureBannerPromoIsApplied()
  }, [location.pathname])

  const onCouponShown = () => {
    // Prevent showing the popup again after was viewed once
    snackbarShownOnce.current = true
    removePendingCouponDialog()
  }

  useEffect(() => {
    if (
      snackbarShownOnce.current ||
      location.pathname?.startsWith(ONBOARDING_URL)
    ) {
      return
    }

    if (coupon?.discountType === DISCOUNT_TYPES.GIFT_CARD) {
      dialog.open(
        <GiftCardSuccessDialog
          open
          onClose={() => dialog.close()}
          title={t.get('general.promo_code_success_dialog.title.gift_card')}
          subtitle={t.get(
            'general.promo_code_success_dialog.subtitle.gift_card',
            {
              v1: formatPrice(
                coupon.metadata.remainingValue,
                coupon.metadata.currency
              ),
            }
          )}
          onDismiss={() => dialog.close()}
        />
      )
      onCouponShown()
    } else if (
      coupon?.code &&
      !isLoadingDiscount &&
      !hideCouponPopup &&
      discount?.value?.promoCode === coupon?.code
    ) {
      displaySnackBar()
      onCouponShown()
    }
  }, [coupon, isLoadingDiscount, discount, location.pathname])

  return null
}

export default PromoCodeDialogContainer
