import { type LinksFunction } from '@remix-run/node'
import { type MetaFunction } from '@remix-run/react'
import MixtilesSans400 from 'media/fonts/MixtilesSans-400Regular.woff'
import MixtilesSans500 from 'media/fonts/MixtilesSans-500Medium.woff'
import MixtilesSans700 from 'media/fonts/MixtilesSans-700Bold.woff'
import MixtilesSans900 from 'media/fonts/MixtilesSans-900Black.woff'
import { viewportMeta } from 'utils/meta'

export const meta: MetaFunction = ({ location }) => {
  let title = 'Mixtiles - Turn your photos into affordable, stunning wall art'
  let description =
    'Mixtiles are printed framed pictures that stick to any wall, leave no damage, and can be moved around. The entire experience is super easy - pick your best pics, choose frames, select the style you like, we ship them free right to your door, you stick them to your wall. It’s that easy!'
  let url = 'https://www.mixtiles.com'
  let siteName = 'Mixtiles'

  if (location.pathname.startsWith('/art')) {
    title = 'Mixtiles Art - Beautiful framed art that sticks to any wall'
    description =
      'Beautiful framed art from the best artists in the world. Artwork comes already framed and ready to stick and re-sticks to any wall. No nails needed, no marks on your walls. Fast and free shipping'
    url = 'https://www.mixtiles.com/art'
    siteName = 'Mixtiles Art'
  } else if (location.pathname.startsWith('/collection/home')) {
    title = 'Mixtiles - Decorate Your Walls in Minutes with Stunning Wall Art'
  }

  return [
    {
      name: 'viewport',
      content: viewportMeta({ interactiveWidget: 'resizes-visual' }),
    },
    { charset: 'utf-8' },
    { title },
    { name: 'theme-color', content: '#FFFFFF' },
    { name: 'msapplication-TileColor', content: '#da532c' },
    { name: 'description', content: description },
    { property: 'og:title', content: title },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: url },
    { property: 'og:site_name', content: siteName },
    { property: 'og:description', content: description },
    { property: 'og:image', content: 'https://www.mixtiles.com/preview3.jpg' },
    { property: 'og:email', content: 'hi@mixtiles.com' },
    { property: 'fb:page_id', content: 'mixtiles' },
    { property: 'twitter:card', content: 'summary' },
    { property: 'twitter:url', content: url },
    { property: 'twitter:title', content: title },
    { property: 'twitter:description', content: description },
    { name: 'p:domain_verify', content: '41cc253422097f4fa383e71ff613812c' },
  ]
}

export const links: LinksFunction = () => {
  return [
    /* <!--
                            manifest.json provides metadata used when your web app is added to the
                            homescreen on Android. See https://developers.google.com/web/fundamentals/engage-and-retain/web-app-manifest/
                          --> */
    { rel: 'manifest', href: '/manifest.json' },
    { rel: 'shortcut icon', href: '/favicon.ico' },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      sizes: '32x32',
      href: '/favicon-32x32.png',
      type: 'image/png',
    },
    {
      rel: 'icon',
      sizes: '16x16',
      href: '/favicon-16x16.png',
      type: 'image/png',
    },
    {
      rel: 'mask-icon',
      color: '#5bbad5',
      href: '/safari-pinned-tab.svg',
    },
    {
      rel: 'preload',
      as: 'font',
      href: MixtilesSans400,
      type: 'font/woff2',
      crossOrigin: 'anonymous',
    },
    {
      rel: 'preload',
      as: 'font',
      href: MixtilesSans500,
      type: 'font/woff2',
      crossOrigin: 'anonymous',
    },
    {
      rel: 'preload',
      as: 'font',
      href: MixtilesSans700,
      type: 'font/woff2',
      crossOrigin: 'anonymous',
    },
    {
      rel: 'preload',
      as: 'font',
      href: MixtilesSans900,
      type: 'font/woff2',
      crossOrigin: 'anonymous',
    },
    // SEO langage per domain
    { rel: 'alternate', hrefLang: 'en', href: 'https://www.mixtiles.com' },
    { rel: 'alternate', hrefLang: 'de', href: 'https://www.mixtiles.de' },
    // Usercentrics
    { rel: 'preconnect', href: '//privacy-proxy.usercentrics.eu' },
    {
      rel: 'preload',
      href: '//privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js',
      as: 'script',
    },
  ]
}
